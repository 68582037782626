<template>
  <div class="mian">
    <a-form
      layout="inline"
      :model="formState"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item>
        <a-input v-model:value="formState.mobile" placeholder="请输入手机号">
          <template #prefix
            ><UserOutlined style="color: rgba(0, 0, 0, 0.25)"
          /></template>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input-password
          v-model:value="formState.user_pwd"
          placeholder="请输入密码"
          autocomplete
        >
          <template #prefix
            ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
          /></template>
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <!-- <a-checkbox v-model="checked">Checkbox</a-checkbox> -->
        <div style="display: inline-block;">
          <span class="register" @click="$router.push({name:'register'})">免费注册</span> | <a style="color: #333;" href="https://online.rondexpo.com/word/2024buildManual.pdf" target="_blank" rel="noopener noreferrer">操作手册</a>
        </div>
        <span class="forgot-password" @click="$router.push({name:'recover'})">忘记密码</span>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" :disabled="formState.mobile === '' || formState.user_pwd === ''">登录</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { login } from '../../api';
import { useUserStore } from '../../store';
// import { message } from 'ant-design-vue'
export default {
  components: {
    UserOutlined,
    LockOutlined
  },
  data () {
    return ({
      formState: {
        mobile: '',
        user_pwd: ''
      },
      checked: false
    })
  },
  methods: {
    async handleFinish () {
      // this.$store.dispatch('Login', this.formState)
      const userStore = useUserStore()
      userStore.Login(this.formState)
      // console.log(this.formState);
      // const response = await login(this.formState)
      // if (response.code*1 === 200) {
      //   // 获取用户基本信息存储到session中
      //   window.sessionStorage.setItem('user-id',response.id)
      //   window.sessionStorage.setItem('user-status',response.status)
      //   window.sessionStorage.setItem('user_name',response.user_name)
      //   this.$router.push('/')
      // } else{
      //   message.error(response.msg)
      // }
    },
    handleFinishFailed () {
    }
  }
}
</script>
<style lang="less" scoped>
.mian{
  min-width: 260px;
  width: 368px;
  margin: 0 auto;
}
.ant-form-item{
  width: 100%;
  margin-bottom: 24px;
}
.ant-input-affix-wrapper{
  height: 40px;
}
.ant-btn{
  width: 100%;
  height: 40px;
}
.register{
  cursor: pointer;
}
.forgot-password{
  float: right;
  cursor: pointer;
}
</style>
